<template>
  <Sidebar
    v-model:visible="visible"
    class="p-sidebar-lg"
    position="right"
    :showCloseIcon="false"
  >
    <Loading v-if="updating" :title="'Updating reward...'" :full="false" />
    <div v-show="!updating">
      <div class="flex">
        <h3>Update reward</h3>
      </div>
      <Form
        :fields="updateFields"
        :context="updateEntity"
        @onChange="updateRewardType"
        @onAction="onUpdate"
        :actionLabel="'Update reward'"
        :actionIcon="'pi pi-plus-circle'"
      />
    </div>
  </Sidebar>
</template>

<script>
import { computed } from "vue";
import { ref, watch } from "vue";
import { RewardType } from "../utils/RewardUtils";

export default {
  components: {},
  emits: ["update:isVisible", "onUpdate"],
  props: ["isVisible", "updating", "entity"],
  setup(props, { emit }) {
    const visible = ref(false);

    const targetTypes = [
      { title: "All", id: "All" },
      { title: "Free only", id: "FreeOnly" },
      { title: "Subscription only", id: "SubscribersOnly" },
      { title: "Staff only", id: "Staff" },
    ];

    const updateRewardType = (val) => {
      selectedRewardType.value = val?.rewardType;
    };

    const selectedRewardType = ref(props.entity?.rewardTypes);

    const updateEntity = ref(props.entity);

    const baseFields = [
      {
        id: "titleLong",
        type: "text-input",
        title: "Long Title",
        help: "Full title of the reward",
      },
      {
        id: "titleShort",
        type: "text-input",
        title: "Short Title",
        help: "Short title of the reward",
      },
      {
        id: "bottomTitle",
        type: "text-input",
        title: "Bottom Title",
        help: "Bottom section title",
      },
      {
        id: "bottomIcon",
        type: "imageUpload",
        title: "Bottom Icon",
        help: "Icon for the bottom section",
      },
      {
        id: "description",
        type: "html-input",
        title: "Description",
        config: {
          blackTextColor: true,
        },
        help: "Detailed description of the reward",
      },
      {
        id: "rewardShort",
        type: "text-input",
        title: "Short Reward",
        help: "Brief description of the reward",
      },
      {
        id: "isTeamReward",
        type: "switch",
        title: "Team Reward",
        help: "Indicates if the reward is for teams",
      },
      {
        id: "isRecurring",
        type: "switch",
        title: "Recurring",
        help: "Indicates if the reward is recurring",
      },
      {
        id: "expireDate",
        type: "calendar",
        title: "Expiration Date",
        help: "Date when the reward expires",
      },
      {
        id: "enabled",
        type: "switch",
        title: "Enabled",
        help: "Indicates if the reward is enabled",
      },
      {
        id: "automaticClaim",
        type: "switch",
        title: "Automatic Claim",
        help: "Specifies if the reward is automatically claimed",
      },
      {
        id: "targetType",
        type: "dropdown",
        title: "Target Type",
        help: "Specifies the target type for the reward",
        config: {
          options: targetTypes,
          optionLabel: "title",
          optionValue: "id",
          placeholder: "Please select type",
        },
      },
      {
        id: "rewardConfigurations",
        type: "perkConfig",
        title: "Rewards",
        config: {
          title: "Reward Perks",
          help: "Perks given when a user completes the reward .",
          enabled: true,
          configs: [],
        },
      },
    ];

    const amountTargetField = {
      id: "amountTarget",
      type: "number-input",
      title: "Amount Target",
      help: "The target amount to be spent",
    };

    const numberOfFriendsToInviteField = {
      id: "numberOfFriendsToInvite",
      type: "number-input",
      title: "Number of Friends to Invite",
      help: "The target amount to be invite",
    };

    const updateFields = computed(() => {
      if (selectedRewardType.value === RewardType.SpendReward)
        return [...baseFields, amountTargetField];
      if (selectedRewardType.value === RewardType.InviteFriendReward)
        return [...baseFields, numberOfFriendsToInviteField];

      return baseFields;
    });

    async function onUpdate(formContext) {
      formContext.rewardConfigurations = formContext?.rewardConfigurations?.map(
        (x) => {
          if (x.venueId && !x.venueId?.id)
            return { ...x, venueId: { id: x.venueId } };
          return x;
        }
      );

      emit("onUpdate", {
        id: updateEntity.value?.id,
        rewardType: updateEntity.value?.rewardType,
        ...formContext,
      });
    }

    // Watch for changes from parent
    watch(
      () => props.isVisible,
      (newVal) => {
        visible.value = newVal;
      }
    );

    watch(
      () => props.entity,
      (newVal) => {
        const rewardConfigurations = newVal.rewardConfigurations?.map((x) => {
          if (x.venueId?.id) return { ...x, venueId: x.venueId?.id };
          return x;
        });

        updateEntity.value = { ...newVal, rewardConfigurations };
        selectedRewardType.value = newVal?.rewardType;
      }
    );

    // Emit event when visibility changes
    watch(visible, (newVal) => {
      emit("update:isVisible", newVal);
    });

    return {
      visible,
      updateRewardType,
      updateEntity,
      updateFields,
      onUpdate,
    };
  },
};
</script>
