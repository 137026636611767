<template>
  <div v-if="loaded" class="flex flex-column">
    <div
      v-for="field in fields"
      :key="field.id"
      class="field"
      :class="{
        'flex-row-reverse justify-content-end align-items-center':
          field.horizontal != null && field.horizontal == true,
      }"
    >
      <label
        v-if="field.title != null"
        :for="field.id"
        class="font-medium text-sm"
        :class="{
          'mb-0 ml-2': field.horizontal != null && field.horizontal == true,
        }"
      >
        {{ field.title }}
        <small
          v-if="formContext?.phoneValid == false && field.title == 'Phone'"
          style="color: red; opacity: 0.5"
          >Not valid</small
        >
      </label>

      <MultiSelect
        v-if="field.type == 'multi' && field.config.format == null"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :filter="true"
        :options="field.config.options"
        :optionLabel="field.config.optionLabel"
        :optionValue="field.config.optionValue"
        :placeholder="field.config.placeholder"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      >
      </MultiSelect>

      <MultiSelect
        v-else-if="field.type == 'multi' && field.config.format != null"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :options="field.config.options"
        :optionLabel="field.config.optionLabel"
        :optionValue="field.config.optionValue"
        :placeholder="field.config.placeholder"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value">
            {{ field.config.format(slotProps.value) }}
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          {{ field.config.format(slotProps.option) }}
        </template>
      </MultiSelect>

      <InputText
        v-if="field.type == 'text-input'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :disabled="field.config != null ? field.config.disabled : false"
        :readonly="field.config != null ? field.config.readonly : false"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <InputText
        v-if="field.type == 'text-datetime'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :disabled="field.config != null ? field.config.disabled : false"
        :readonly="true"
        :modelValue="formatDate(getProperty(field))"
        @update:modelValue="updateProperty(field, $event)"
      />

      <InputText
        v-else-if="field.type == 'country'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :disabled="field.config != null ? field.config.disabled : false"
        :readonly="true"
        :modelValue="getCountry(field)"
      />

      <InputText
        v-else-if="field.type == 'text-input-phone'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :disabled="field.config != null ? field.config.disabled : false"
        :readonly="field.config != null ? field.config.readonly : false"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <InputNumber
        v-else-if="field.type == 'number-input'"
        :locale="'da-DK'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :modelValue="getProperty(field)"
        :max="field.max"
        :min="field.min"
        :suffix="field.suffix"
        @update:modelValue="updateProperty(field, $event)"
      />

      <InputNumber
        v-else-if="field.type == 'number-input-decimal'"
        :locale="'da-DK'"
        :mode="'decimal'"
        :minFractionDigits="2"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <Textarea
        v-else-if="field.type == 'text-area'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :autoResize="true"
        rows="5"
        cols="30"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <InputSwitch
        v-else-if="field.type == 'switch'"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <Calendar
        v-else-if="field.type == 'calendar'"
        :id="field.id"
        :showIcon="true"
        :aria-describedby="field.id + '-help'"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <Calendar
        v-else-if="field.type == 'time'"
        :id="field.id"
        :showIcon="true"
        :aria-describedby="field.id + '-help'"
        :modelValue="getTimeProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
        showTime
        hourFormat="24"
      />

      <Dropdown
        v-else-if="field.type == 'dropdown' && field.config.format == null"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :filter="field.filter"
        :showClear="field.filter"
        :options="field.config.options"
        :optionLabel="field.config.optionLabel"
        :optionValue="field.config.optionValue"
        :placeholder="field.config.placeholder"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <Dropdown
        v-else-if="field.type == 'dropdown' && field.config.format != null"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :options="field.config.options"
        :optionLabel="field.config.optionLabel"
        :optionValue="field.config.optionValue"
        :placeholder="field.config.placeholder"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value">
            {{ field.config.format(slotProps.value) }}
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          {{ field.config.format(slotProps.option) }}
        </template>
      </Dropdown>

      <div
        v-else-if="field.type == 'imageUpload'"
        class="flex justify-content-center flex-wrap"
      >
        <div
          class="w-full bg-gray-300 mb-1 flex justify-content-center flex-wrap"
        >
          <Image
            class="mx p-2"
            v-if="formContext[field.id]"
            :src="getImageProperty(field)"
            width="250"
            preview
          />
        </div>
        <div
          v-if="field.config != null ? !field.config.readonly : true"
          class="flex w-full gap-2"
        >
          <Button
            v-if="formContext[field.id]"
            icon="pi pi-times"
            class="border-none bg-gray-500"
            aria-label="Cancel"
            @click="removeImage(field.id)"
          />
          <Button
            class="w-full h-14 bg-gray-200 border-none relative cursor-pointer flex"
            style="color: gray"
          >
            <input
              style="opacity: 0"
              class="absolute w-full cursor-pointer"
              @change="updateImageProperty($event, field)"
              :id="field.id"
              :key="field.id"
              type="file"
              :accept="
                field.accept ? field.accept : 'image/png, image/jpeg, image/png'
              "
            />
            <p class="h-0 m-auto">
              {{
                formContext[field.id]
                  ? "Upload new image..."
                  : "Upload image..."
              }}
            </p>
          </Button>
        </div>
      </div>

      <vue-tel-input
        v-else-if="field.type == 'phone-input'"
        :inputClasses="'bg-blue-400'"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
        @validate="formContext.phoneValid = $event.valid"
        @country-changed="formContext.phoneCountryCode = $event.dialCode"
        :preferred-countries="['DK', 'NO', 'FI', 'GB']"
        :validCharactersOnly="true"
      />

      <html-editor
        v-else-if="field.type == 'html-input'"
        :class="field.inputClass"
        :showHeaders="field.config != null ? field.config.disabled : false"
        :id="field.id"
        :aria-describedby="field.id + '-help'"
        :disabled="field.config != null ? field.config.disabled : false"
        :blackTextColor="
          field.config != null ? field.config.blackTextColor : false
        "
        :readonly="field.config != null ? field.config.readonly : false"
        :modelValue="getProperty(field)"
        @update:modelValue="updateProperty(field, $event)"
      />

      <PerkConfigFormField
        v-else-if="field.type === 'perkConfig'"
        :perkConfigs="getProperty(field)"
        :help="field?.config?.help"
        :title="field?.config?.title"
        @update:perkConfigs="updateProperty(field, $event)"
      />

      <component
        v-else
        :is="field.type"
        v-bind="{ context: formContext, field: field }"
      />

      <small v-if="field.help != null" :id="field.id + '-help'" class="mt-1">{{
        field.help
      }}</small>
    </div>

    <Button
      v-if="hasAction == null || hasAction == true"
      class="flex-1 mt-3"
      @click="executeAction"
      :label="actionLabel"
      :icon="actionIcon"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch, inject } from "vue";
import getCountries from "@/utils/getCountries.js";
import HtmlEditor from "./HtmlEditor.vue";
import PerkConfigFormField from "./PerkConfigFormField.vue";

function getByString(o, path) {
  var schema = o; // a moving reference to internal objects within obj
  var pList = path.split(".");

  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  return schema[pList[len - 1]];
}

function setByString(o, path, value) {
  var schema = o; // a moving reference to internal objects within obj
  var pList = path.split(".");
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}

export default {
  components: { HtmlEditor, PerkConfigFormField },
  emits: ["onAction", "onChange"],
  props: ["context", "fields", "actionLabel", "actionIcon", "hasAction"],
  setup(props, { emit }) {
    watch(
      () => JSON.stringify(props.context),
      (updatedContext) => {
        updateFormContext(JSON.parse(updatedContext));
      }
    );

    const loaded = ref(false);
    const formContext = ref();
    const image = ref();

    const useFormatDate = inject("useFormatDate");

    function updateFormContext(context) {
      if (context != null)
        formContext.value = JSON.parse(JSON.stringify(context));
    }

    function getProperty(field) {
      if (field.path == null) {
        return formContext.value[field.id];
      }

      return getByString(formContext.value, field.path);
    }

    function getCountry(field) {
      if (field.path == null) {
        return getCountries().getCountryName(formContext.value[field.id]);
      }

      return getCountries().getCountryName(
        getByString(formContext.value, field.path)
      );
    }

    function getTimeProperty(field) {
      if (field.path == null) {
        if (formContext.value[field.id] == null) {
          var date = new Date(Date.now());
          date.setHours(0, 0, 0, 0);
          formContext.value[field.id] = date;
          return date;
        }
        return new Date(formContext.value[field.id]);
      }

      return getByString(formContext.value, field.path);
    }

    function getImageProperty(field) {
      var file = formContext.value[field.id];
      if (!file.name) {
        return file;
      }
      return URL.createObjectURL(file);
    }

    function removeImage(id) {
      formContext.value[id] = "";
      emit("onChange", formContext.value);
    }

    function updateProperty(field, payload) {
      if (field.path == null) {
        formContext.value[field.id] = payload;
      } else {
        setByString(formContext.value, field.path, payload);
      }

      emit("onChange", formContext.value);
    }

    function executeAction() {
      emit("onAction", formContext.value);
    }

    onMounted(() => {
      updateFormContext(props.context);

      loaded.value = true;
    });

    function updateImageProperty(event, field) {
      event.preventDefault();
      const input = document.getElementById(field.id);
      const file = input.files[0];

      formContext.value[field.id] = file;
      emit("onChange", formContext.value);
    }

    return {
      getProperty,
      getTimeProperty,
      getCountry,
      getImageProperty,
      updateProperty,

      formContext,
      updateImageProperty,
      removeImage,
      image,
      loaded,
      executeAction,

      formatDate: useFormatDate().formatDateTimeFull,
    };
  },
};
</script>
<style>
.vti__input {
  font-size: 1rem;
  color: #495057;
  padding: 0.5rem 0.5rem;
}
.vue-tel-input {
  border-color: lightgray;
}
.p-image-preview {
  transform: rotate(0) scale(0.6) !important;
}

.htmleditor .editor {
  background-image: linear-gradient(
    to right top,
    #081654,
    #081654,
    #122886,
    #122886,
    #122886
  );
}

.white .editor {
  background: white;
}
</style>
