import * as apiGetRewards from '@/api/rewards/getRewards';
import * as apiUpdateReward from '@/api/rewards/updateReward';
import * as apiCreateReward from '@/api/rewards/createReward';
import * as apiDeleteReward from '@/api/rewards/deleteReward';

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        rewardListItems: [],
    }),
    mutations: {
        isLoading(state, loading) {
            state.loading = loading;
        },
        gotRewards(state, rewards) {
            state.rewardListItems = rewards
        },
    },
    actions: {
        async getRewardListItems({ commit }) {
            commit('isLoading', true);

            const result = await apiGetRewards.get();
            if (result) {
                commit('gotRewards', result)
            }
            commit('isLoading', false);
        },

        async ensureRewardListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.rewardListItems?.length === 0) {
                await dispatch('getRewardListItems')
            } 
            commit('isLoading', false)
        },


        async create({ commit }, newReward) {
            commit('isLoading', true)

            await apiCreateReward.post(newReward);

            commit('isLoading', false)
        },

        async update({ commit }, reward) {
            commit('isLoading', true)

            await apiUpdateReward.put(reward.id, reward);

            commit('isLoading', false)
        },

        async delete({ commit }, rewardId) {
            commit('isLoading', true)

            await apiDeleteReward.del(rewardId);

            commit('isLoading', false)
        },

    },
    getters: {
        rewardListItems: (state) => state.rewardListItems
    }
}