

const RewardType = {
  SpendReward: "SpendReward",
  InviteFriendReward: "InviteFriendReward"
}

function getRecurrenceTypeTitle(isRecurring){
    return isRecurring ? 'Always-On' : "One-Off"
}

function getRewardTypeTitle(rewardType){
     switch(rewardType)  {
      case RewardType.SpendReward: 
      return "Spending";
      case RewardType.InviteFriendReward:
        return "Invites"
        default: 
        return "Unknown"

    }
}

function getExpirationDateTitle(date){

  return new Date(date)?.toLocaleDateString("da-DK") ?? date;
}

function getRewardTypeTargetValue(reward){
    switch(reward.rewardType)  {
        case RewardType.SpendReward: 
        return reward?.amountTarget + " kr.";
        case RewardType.InviteFriendReward:
        return reward?.numberOfFriendsToInvite;
        default: 
        return "Unknown"

    }
  }
  


export {getRecurrenceTypeTitle, getRewardTypeTitle, getExpirationDateTitle, getRewardTypeTargetValue, RewardType}