<template>
  <CreateRewardSidebar
    v-model:isVisible="createRewardSidebarIsVisible"
    @onCreate="createEntity"
    :creating="creating"
  />

  <UpdateRewardSidebar
    v-model:isVisible="updateRewardSidebarIsVisible"
    @onUpdate="updateEntity"
    :updating="creating"
    :entity="selectedEntity"
  />

  <Dialog
    v-model:visible="deleteRewardDialogIsVisible"
    :draggable="false"
    modal
    header="Header"
    :style="{ width: '40rem' }"
  >
    <template #header>
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <h3 class="font-bold white-space-nowrap m-0 p-0">Delete Reward</h3>
      </div>
    </template>
    <div class="text-center">
      <p>
        You are about to
        <b>delete</b> the reward "{{
          rewardSelectedForDeletion?.titleShort
        }}"
      </p>
      <p>Pleas confirm your action</p>
      <Button
        @click="deleteEntity(rewardSelectedForDeletion.id)"
        icon="pi pi-trash"
        severity="danger"
        class="px-8 mt-4"
        :label="'Delete'"
      />
    </div>
  </Dialog>

  <div class="surface-section p-6 pt-0">
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
        <div class="text-700 mb-5 line-height-3 mb-5">
          {{ pageDescription }}.
        </div>
      </div>
      <div class="flex justify-content-end align-items-center">
        <Button
          v-if="hasPermission('np.backoffice.rewards.edit')"
          @click="
            createRewardSidebarIsVisible = !createRewardSidebarIsVisible
          "
          :label="'New Reward'"
          icon="pi pi-plus-circle"
        />
      </div>
    </div>

    <Loading
      v-if="loading"
      :full="false"
      :title="'Loading ' + pageTitle + '...'"
    />

    <div v-else class="bg-gray-200 border-round p-2">
      <h3 class="text-md mb-4 m-0 text-gray-600">NightPay Rewards</h3>

      <DataTable
        :value="rewards"
        dataKey="id"
        class="p-datatable-lg"
        :rowHover="true"
        responsiveLayout="scroll"
      >
        <template #empty> No {{ pageTitle }} found. </template>
        <template #loading> Loading {{ pageTitle }}. Please wait. </template>
        <Column header="Title">
          <template #body="{ data }">
            {{ data?.titleLong }}
          </template>
        </Column>
        <Column header="Solo/team">
          <template #body="{ data }">
            <div class="flex">
              <span
                class="p-2 bg-gray-900 text-center w-4rem text-white border-round"
              >
                {{ data.isTeamReward ? "TEAM" : "SOLO" }}
              </span>
            </div>
          </template>
        </Column>
        <Column header="Recurrence">
          <template #body="{ data }">
            <div class="flex">
              <span
                class="p-2 bg-gray-900 text-center w-6rem text-white border-round"
              >
                {{ getRecurrenceTypeTitle(data?.isRecurring) }}
              </span>
            </div>
          </template>
        </Column>

        <Column header="Expiration">
          <template #body="{ data }">
            <div class="flex">
              <span
                class="p-2 bg-gray-900 text-center w-6rem text-white border-round"
              >
                {{ getExpirationDateTitle(data.expireDate) }}</span
              >
            </div>
          </template>
        </Column>
        <Column header="Type" field="venueName">
          <template #body="{ data }">
            <div class="flex gap-2">
              <span
                class="p-2 bg-blue-400 w-7rem text-center text-white border-round"
              >
                {{ getRewardTypeTitle(data.rewardType) }}
              </span>
            </div>
          </template>
        </Column>
        <Column header="Target">
          <template #body="{ data }">
            <div class="flex">
              <span
                class="p-2 w-7rem bg-blue-400 text-center text-white border-round"
              >
                {{ getRewardTypeTargetValue(data) }}
              </span>
            </div>
          </template></Column
        >

        <Column header="Status">
          <template #body="{ data }">
            <Chip v-if="data.enabled" label="Enabled" class="chip-enabled" />
            <Chip v-else label="Disabled" class="chip-disabled" />
          </template>
        </Column>

        <Column
          v-if="hasPermission('np.backoffice.rewards.edit')"
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="{ data }">
            <div class="flex gap-2">
              <Button
                @click="showUpdateSidebar(data)"
                type="button"
                icon="pi pi-pencil"
              ></Button>
              <Button
                @click="showDeleteDialog(data)"
                type="button"
                icon="pi pi-trash"
              ></Button>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { uploadFile } from "@/services/fileUploadService";
import CreateRewardSidebar from "./components/CreateRewardSidebar.vue";
import UpdateRewardSidebar from "./components/UpdateRewardSidebar.vue";

import {
  getRewardTypeTitle,
  getRecurrenceTypeTitle,
  getExpirationDateTitle,
  getRewardTypeTargetValue,
} from "./utils/RewardUtils";

export default {
  inject: ["hasPermission"],
  components: { CreateRewardSidebar, UpdateRewardSidebar },
  methods: {
    getRewardTypeTitle,
    getRecurrenceTypeTitle,
    getExpirationDateTitle,
    getRewardTypeTargetValue,
  },
  setup() {
    const storeActionGetEntities = "rewards/getRewardListItems";
    const storeGetterGetEntities = "rewards/rewardListItems";
    const storeActionEnsureEntities = "rewards/ensureRewardListItems";
    const storeActionCreateEntity = "rewards/create";
    const storeActionUpdateEntity = "rewards/update";
    const storeActionDeleteEntity = "rewards/delete";

    const pageTitle = "NightPay Rewards";
    const pageDescription =
      "Here you can manage NightPay Rewards and configure settings as well as rewards";
    const loading = ref(false);
    const creating = ref(false);

    const createRewardSidebarIsVisible = ref(false);
    const updateRewardSidebarIsVisible = ref(false);
    const deleteRewardDialogIsVisible = ref(false);

    const store = useStore();
    const toast = useToast();
    const rewards = ref([]);
    const rewardSelectedForDeletion = ref();

    const selectedEntity = ref();

    function showDeleteDialog(reward) {
      rewardSelectedForDeletion.value = reward;
      deleteRewardDialogIsVisible.value = true;
    }

    function showUpdateSidebar(reward) {
      selectedEntity.value = reward;
      updateRewardSidebarIsVisible.value = true;
    }

    async function loadRewards() {
      await store
        .dispatch(storeActionEnsureEntities)
        .then(() => {
          const loadedEntities = store.getters[storeGetterGetEntities];
          rewards.value = loadedEntities;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not get rewards" + ": " + err,
            life: 3000,
          });
        });
    }

    async function loadRequiredData() {
      loading.value = true;

      await Promise.all([loadRewards()]).finally(
        () => (loading.value = false)
      );
    }

    async function reloadData() {
      loading.value = true;
      await store
        .dispatch(storeActionGetEntities)
        .then(() => {
          const loadedEntities = store.getters[storeGetterGetEntities];
          rewards.value = loadedEntities;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not get rewards" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (loading.value = false));
    }

    async function handleImageUpload(formContext) {
      if (formContext.bottomIcon == selectedEntity.value?.bottomIcon)
        return formContext.bottomIcon;

      var imageContainer = "rewards";
      var iconUrl = "";
      if (formContext.bottomIcon) {
        var logoFile = await uploadFile(
          formContext.bottomIcon,
          "rewards",
          imageContainer,
          store
        );
        iconUrl = logoFile.url;
      }
      return iconUrl;
    }

    async function createEntity(formContext) {
      creating.value = true;

      var imageUrl = await handleImageUpload(formContext);
      formContext.bottomIcon = imageUrl;

      await store
        .dispatch(storeActionCreateEntity, formContext)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Reward created",
            life: 3000,
          });
          createRewardSidebarIsVisible.value = false;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not create reward" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (creating.value = false));

      reloadData();
    }

    async function updateEntity(formContext) {
      creating.value = true;

      var imageUrl = await handleImageUpload(formContext);

      formContext.bottomIcon = imageUrl;

      await store
        .dispatch(storeActionUpdateEntity, {
          ...formContext,
          id: selectedEntity.value?.id,
        })
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Reward updated",
            life: 3000,
          });
          updateRewardSidebarIsVisible.value = false;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not update reward" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (creating.value = false));

      reloadData();
    }

    async function deleteEntity(id) {
      deleteRewardDialogIsVisible.value = false;
      await store
        .dispatch(storeActionDeleteEntity, id)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Reward deleted",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not delete reward" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (creating.value = false));

      reloadData();
    }

    onMounted(() => {
      loadRequiredData();
    });

    return {
      pageTitle,
      pageDescription,
      loading,
      rewards,
      rewardSelectedForDeletion,
      createRewardSidebarIsVisible,
      updateRewardSidebarIsVisible,
      deleteRewardDialogIsVisible,
      createEntity,
      deleteEntity,
      updateEntity,
      creating,
      showDeleteDialog,
      showUpdateSidebar,
      selectedEntity,
    };
  },
};
</script>
