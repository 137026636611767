<template>
  <div
    :key="title"
    class="field border-1 border-gray-300 p-2 border-round shadow-1"
  >
    <div class="flex m-0 p-0">
      <h3 class="text-gray-700 mb-0 pb-0 w-fit">{{ title }}</h3>
    </div>
    <p class="pb-3 m-0 text-xs pt-1">{{ help }}</p>

    <div>
      <div
        v-for="(config, i) in perkConfigs"
        :key="i"
        class="field border-1 p-2 border-gray-300 rounded shadow-1"
      >
        <div class="flex justify-between">
          <p class="font-medium">Perk {{ i + 1 }}</p>
          <Button
            @click="removeSignupPerk(i)"
            class="ml-auto h-2rem p-button-danger"
          >
            Remove
          </Button>
        </div>

        <Dropdown
          v-model="config.perkId.id"
          :options="perks"
          filter
          optionLabel="title"
          optionValue="id"
          placeholder="Select Perk"
        />

        <label v-if="!config.isFromNightPay" class="mt-3">Venue</label>
        <Dropdown
          v-if="!config.isFromNightPay"
          v-model="config.venueId"
          :options="venues"
          filter
          optionLabel="name"
          optionValue="id"
          placeholder="All"
        />

        <label class="mt-3">Is from NightPay</label>
        <InputSwitch v-model="config.isFromNightPay" />

        <label class="mt-3">Quantity</label>
        <InputNumber v-model="config.quantity" style="width: 200px" />

        <label class="mt-3">Expires</label>
        <InputSwitch v-model="config.expires" />

        <div v-if="config.expires">
          <label class="mt-2 mr-2">Expires after</label>
          <InputNumber v-model="config.expire" />
          <Dropdown
            class="ml-1"
            v-model="config.expireType"
            :options="durationTypes"
            optionLabel="title"
            optionValue="id"
            placeholder="Select duration"
          />

          <div class="my-2">or</div>

          <label class="mt-2 mr-2"
            >Expires at date (overrules expires in)</label
          >
          <Calendar v-model="config.expireDate" placeholder="Select date" />
        </div>
      </div>
      <Button @click="addSignupPerk">Add Perk</Button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    perkConfigs: Array,
    title: String,
    help: String,
  },
  emits: ["update:perkConfigs"],
  setup(props, { emit }) {
    const store = useStore();
    const perks = ref([]);
    const venues = ref([]);
    const durationTypes = ref([
      { id: "Day", title: "day(s)" },
      { id: "Week", title: "week(s)" },
      { id: "Month", title: "month(s)" },
      { id: "Year", title: "year(s)" },
    ]);

    function loadRequiredData() {
      store.dispatch("perks/ensurePerkListItems").then(() => {
        perks.value = store.getters["perks/perkListItems"];
      });

      store.dispatch("venues/ensureVenueListItems").then(() => {
        venues.value = [
          { id: null, name: "All" },
          ...store.getters["venues/venueListItems"],
        ];
      });
    }

    function addSignupPerk() {
      const updatedPerks = [...props.perkConfigs];
      updatedPerks.push({
        perkId: { id: "" },
        venueId: undefined,
        isFromNightPay: false,
        quantity: 1,
        expires: false,
        expire: 1,
        expireType: "Month",
      });
      emit("update:perkConfigs", updatedPerks);
    }

    function removeSignupPerk(index) {
      const updatedPerks = [...props.perkConfigs];
      updatedPerks.splice(index, 1);
      emit("update:perkConfigs", updatedPerks);
    }

    onMounted(() => {
      loadRequiredData();
    });

    return {
      perks,
      venues,
      durationTypes,
      addSignupPerk,
      removeSignupPerk,
    };
  },
};
</script>
